/* new css open */

/* new css close */





/* .custom-styles {
  --ReactInputVerificationCode-itemWidth: 3.5rem !important;
  --ReactInputVerificationCode-itemHeight: 3rem !important;
} */
.coupon-apply {
  --ReactInputVerificationCode-itemWidth: 20%;
  --ReactInputVerificationCode-itemHeight: 54px;
  margin-top: 10px
}
.coupon-apply .css-ae0g2e {
  width: 100%;
}
.css-1ohhda4 {
  width: 100%;
  left: 0;
}
coupon-apply .ReactInputVerificationCode__item {
  position: relative;
  color: #046cde;
  font-weight: 500;
  background-color: #046cde;
}
/*  */
.centervertical {
  height: 100vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
/*  */
.css-jxah1j {
  background: #b1b1b133;
}

/*  */

.inputlabels .MuiFormControl-root {
  width: 100%;
}

/*  */
/* new css open */
@font-face {
  font-family: 'Neutrif Pro';
  src: url('/src/fonts/NeutrifPro-Regular.eot');
  src: url('/src/fonts/NeutrifPro-Regular.eot?#iefix') format('embedded-opentype'),
    url('/src/fonts/NeutrifPro-Regular.woff2') format('woff2'),
    url('/src/fonts/NeutrifPro-Regular.woff') format('woff'),
    url('/src/fonts/NeutrifPro-Regular.ttf') format('truetype'),
    url('/src/fonts/NeutrifPro-Regular.svg#NeutrifPro-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neutrif Pro';
  src: url('/src/fonts/NeutrifPro-Bold.eot');
  src: url('/src/fonts/NeutrifPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('/src/fonts/NeutrifPro-Bold.woff2') format('woff2'),
    url('/src/fonts/NeutrifPro-Bold.woff') format('woff'),
    url('/src/fonts/NeutrifPro-Bold.ttf') format('truetype'),
    url('/src/fonts/NeutrifPro-Bold.svg#NeutrifPro-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}


.payment-wrap {
  padding: 0 40px;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  background: #fff;
  min-height: 100vh;
}

.header-top {
  width: 100%;
  padding: 30px 0 10px 0;
  /* position: fixed;
  top: 0;
  left: 0; */
  background-color: #ffffff;
  z-index: 999;
}

.logos {
  display: flex;
  margin: auto;
  width: 100%;
  max-width: 170px;
}

.payment-wrap-outer {
  /* display: flex; */
  flex-direction: column;
  /* justify-content: space-between; */
}
.middlebody-content {
  display: flex;
  align-items: center;
  /* margin-top: 25%; */
  height: calc(100vh - 210px);
}
.footer-btm-outer {
  position: static !important;
}

.middlebody {
  /* padding-top: 180px; */
}

.middlebody h1 {
  font-family: 'Neutrif Pro';
  font-weight: bold;
  font-size: 30px;
  line-height: 39px;
  text-align: center;
  color: #000000;
  margin-top: 0;
  margin-bottom: 30px;
}
.textcenter {
  text-align: center;
}

.inputlabels {
  font-family: 'Neutrif Pro';
  font-size: 16px;
  color: #000;
}

.fullbody {
  width: 100%;
}

.codeinputs {
  display: flex;
  justify-content: space-between;
}

.codeinputs .fullwidth-input {
  width: 60px;
  height: 60px;
}

.fullwidth-input {
  width: 100%;
}

.fullwidth-input input {
  /* border: 1px solid #ccc;
  border-radius: 4px;
  padding: 14px 14px;
  background: #fff; */


  width: 100%;
  height: 56px;
  background: rgba(177, 177, 177, 0.2);
  border: 0.5px solid #F6F8F9;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 0 14px;

}

.fullwidth-input::before {
  content: '';
  border-bottom: 0 !important;
}

.leftalign {
  text-align: left;
  width: 100%;
  margin-bottom: 30px;
}

.leftalign .MuiFormControlLabel-label {
  font-family: 'Neutrif Pro';
  font-size: 14px;
  color: #000;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #389652 !important;
}
.MuiCheckbox-colorSecondary.Mui-checked .MuiIconButton-label:after {
  /* content: "";
  top: 12px;
  left: 12px;
  width: 14px;
  height: 14px;
    position: absolute;
    border-color: transparent;
    background: #fff url("https://payment.parkingmgt.com/images/check.png");
    display: block;
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: center center;
    border: 2px solid rgba(0, 0, 0, 0.54);
    border-radius: 2px; */
}

.fontsize {
  /* font-weight: bold !important; */
  margin: 10px 0 10px 0;
}

.fontsize2 {
  /* font-weight: bold !important; */
  margin: 10px 0 20px 0;
}

.btn-primary {
  text-align: center;
  justify-content: center;
  display: flex;
  width: 100%;
  padding: 18px 10px;
  font-size: 16px;
  background: #101446;
  color: #fff;
  cursor: pointer;
  border-radius: 50px;
  border-color: #101446;
  border: 0;
  margin: 20px auto 0px;
  font-family: 'Neutrif Pro' !important;
  letter-spacing: 4px;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
}

.btn-primary:hover {
  background: #05093c;
  border-color: #05093c;
}

.btn-primary img {
  width: 11px;
  position: absolute;
  right: 20px;
  top: 19px;
}

.footer-btm {
  position: fixed !important;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
}

@media (max-width:767px) {}

/* new css close */


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ErrorPanelMsg {
  font-size: x-large;
}

.MuiButton-textSecondary {
  color: #fff !important;
  background-color: #000 !important;
  width: 25% !important;
}

.error {
  color: rgb(224, 45, 45);
}

.overlay-process {
  text-align: center;
  justify-content: center;
  margin: auto;
  display: flex;
  width: 80%;
  padding: 10px;
  font-size: larger;
}

.button-pay {
  text-align: center;
  justify-content: center;
  display: flex;
  width: 100%;
  max-width: 140px;
  padding: 8px 0px;
  font-size: 16px;
  background: #389652;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
  border-color: #389652;
  border: 0;
  box-shadow: 0 2px 1px rgb(0 0 0 / 10%), 0 1px 3px rgb(0 0 0 / 30%);
  margin: 50px auto 50px;
  font-family: Nunito, sans-serif !important;
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
}

.logo {
  display: flex;
  margin: auto;
  width: 100%;
  max-width: 100px;
}

.bottom-text {
  max-width: 235px;
  display: block;
  margin: 0 auto;
}

.bottom-text p {
  text-align: center;
  font-size: 14px;
  color: #000000;
}

.MuiFormGroup-root {
  align-items: center;
}

.header {
  width: 100%;
  padding: 10px 0;
  box-shadow: 3px 3px 8px #e8e8e8cc;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 999;
  border-bottom: 1px solid #e1e1e1 !important;
}

.payment-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
}

.payment-wrapper main {
  width: 100%;
}

.footer-inner {
  text-align: center;
  padding: 0 15px;
}

.footer-inner p {
  font-family: 'Neutrif Pro' !important;
  font-size: 16px;
  color: #000;
}

.footer-inner p a {
  color: #000;
  text-decoration: none;
}

.footer {
  border-top: 1px solid #ccc;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
}

#iframeAuthorizeNet {
  width: 100%;
  min-height: calc(100vh - 141px);
  /* min-height: calc(100vh - (178px + -100px)); */
  /* min-height: 600px; */
}

.body {
  padding-top: 70px;
}

.body-text {
  /* padding: 0 15px; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 100%;
  max-width: 375px;
}

.body-text p {
  line-height: 26px;
  font-size: 15px;
  text-align: center;
}

.MuiInput-underline:after {
  border-bottom: 2px solid #389652 !important;
}

.unsubscribe__inner {
  width: 100%;
  max-width: 320px;
  margin: 80px auto;
}

.iHNqDx {
  background-color: #fff !important;
}

.cqQGRX {
  color: #000 !important;
  background-color: #fff !important;
}

.hRfUdb {
  color: #fff !important;
  background-color: #389652 !important;
  border: #389652 !important;
}

.dYwNxH {
  color: #fff !important;
  background-color: #389652 !important;
  border: #389652 !important;
}

.fSWevF {
  color: rgb(255, 255, 255) !important;
  background-color: rgb(105, 104, 104) !important;
}

.shortly__loading {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  font-size: x-large;
}

.checkbox-style .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
  }

  .checkbox-style .MuiIconButton-label:after {
  width: 12px !important;
  height: 12px !important;
}
.htfull {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sama-btn-rounded {
  background: rgb(39 59 112);
  color: rgb(255, 255, 255);
  width: 30px;
  height: 30px;
  border-radius: 50px;
  border: none;
}

@media (max-width:320px) {
  .middlebody h1 {
    margin-bottom: 0px;
    font-size: 24px;
  }
  .header-top {
    padding: 10px 0 20px 0;
  }
  .logos {
    max-width: 150px;
  }
  .payment-wrap {
    padding: 0 30px;
  }
}

.coupon-apply div {
  justify-content: space-between;
}
.coupon-apply .validationcode input {
  width: 50px !important;
  height: 50px;
}

.new {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}
.primary-btn {
  background: #1682c5 !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  margin-top: 10px !important;
  color: #fff !important;
  width: 100%;
  min-height: 52px !important;
}

.dock_header {
  background: none !important;
}

.dockbrandlogo {
  width: 120px;
  margin: 0 auto;
}

.file-wrapper {
  margin-top: 50%;
}
