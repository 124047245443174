/* music page Css::Start */
.music__wrapper .logo {
  display: flex;
  margin: auto;
  width: 100%;
  max-width: 104px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.music__wrapper .header{
  min-height: 50px;
}
.music__inner{
  padding: 0px 15px 40px 15px;
  max-width: 320px;
  margin: 90px auto;
  box-shadow: 0px 2px 6px #e6e0e0;
  display: block;
  box-sizing: border-box;
}
.wait__title{
  text-align: center;
  margin: 0px auto 0;
  display: block;
  padding-top: 30px;
  font-size: 18px;
  color: #000000;
  font-weight: 400;
}
.music__wrapper .form-control{
    min-height: 41px;
    border-radius: 0px;
    border: 1px solid #000;
    box-shadow: none;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    padding: 0 15px;
}
.music__wrapper form label{
    display: none;
}
.music__wrapper form{
  text-align: center;
}
.music__wrapper form .col{
    margin-bottom: 15px;
}
.music__wrapper .title{
  font-size: 22px;
  font-weight: 600;
  color: #606060;
  margin-bottom: 30px; 
  display: inline-block;
}
.players__title{
  margin-bottom: 10px !important;
}
.music__wrapper .field-error{
  color: red;
  text-align: left;
  font-size: 13px;
  font-weight: 400;
}
.add-btn{
  max-width: 100%;
  min-height: 35px;
  border-radius: 0px;     
  margin-bottom: 7px;
  font-size: 15px;
  border: 2px solid #8f8f8f;
  background-color: #8f8f8f;
  color: #fff;
  cursor: pointer;
  margin: 10px 0 15px 0;
  padding: 0px 15px;
}
 
.next-btn{     
  color: #fff;
  border-radius: 0px;
  border: 0px;
  background-color: #cf2027;
  margin-bottom: 7px;
  cursor: pointer;
}
.music__wrapper  .close-btn{
  position: absolute;
  top: 0;
  right: 0;
  max-width: 120px;
  min-width: 40px;
  min-height: 32px;
  color: #fff;
  border-radius: 0px;
  border: 0px;
  background-color: #8f8f8f;
  cursor: pointer;
  display: none;
}
.music__wrapper .btn{
  height: 38px;
  min-height: 19px;
  width: 171px;
  font-weight: 600;
}
 
.music__wrapper .add-form{
  position: relative;
}
.music__wrapper .add-form-list:hover .close-btn{
  display: block;
}
.music__wrapper .add-form-list{
  position: relative;
  padding-top: 32px;
}
 
.pd-tp-0{
  padding-top: 0px !important;
}
 
.music__wrapper .sub__title{
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: #606060;
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
 }
.music__wrapper .add-form-list .items{
  margin-bottom: 7px;
}

 
/* music page Css::End */

/* error modal Css::Start */
.error__modal{
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background-color: #000000a8;
  z-index: 99999999999;
}
.error__modal .overlay{
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgb(105, 105, 105);
  display: none;
}
/* error modal Css::Emd */

/* payment Page Css::Start */
.payment__body{
  padding: 40px ​0px 0px;
}
.payment__wrapper .music__inner{
  max-width: 400px;
}
.payment__wrapper #iframeAuthorizeNet{
  /* min-height: calc(100vh - (328px + -100px)); */
  min-height: 151vh;
  overflow: hidden;
}
/* payment Page Css::End */